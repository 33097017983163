import {
  ActionTextField,
  AddressTextField,
  GOOGLE_MAPS_API_KEY,
} from '@flock/shared-ui'
import { Grid, Box, Typography, CircularProgress } from '@mui/material'
import React from 'react'
import Fade from 'react-reveal/Fade'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { HomepageHeroSectionPresentationalProps } from './homepageHeroSectionTypes'
import HeroImage from '../../../images/hero-image.webp'

const HomepageHeroSectionDesktop = (
  props: HomepageHeroSectionPresentationalProps
) => {
  const { loading, onKeyDown, onChange, onSubmit } = props

  const isSSR = typeof window === 'undefined'

  return (
    <SectionLayout
      name="homepage-hero"
      backgroundColor="midnightBlue.main"
      sx={{ overflow: 'hidden' }}
    >
      <Grid item xs={12}>
        <Box position="relative" zIndex={0}>
          <Fade duration={500} delay={200} distance="5rem" right>
            <Box
              position="absolute"
              component="img"
              src={HeroImage}
              width="779px"
              height="779px"
              sx={{
                right: '-364px',
                borderRadius: '50%',
              }}
            />
          </Fade>
        </Box>
      </Grid>
      <Grid item md={9} sm={12} height="496px">
        <Grid container spacing={2}>
          <Grid
            item
            sm={8}
            display="flex"
            flexDirection="column"
            gap="32px"
            justifyContent="center"
          >
            <Box display="flex" flexDirection="column" gap="32px">
              <Typography
                variant="h1"
                color="white.main"
                sx={{ fontSize: '52px', lineHeight: '100%' }}
              >
                Is your vacation home generating more stress than income?
              </Typography>
              <Typography variant="p1" color="white.main">
                Swap your home for shares of the Overmoon 721 Fund to defer
                taxes, receive distributions, and maintain appreciation
                potential.
              </Typography>
            </Box>
          </Grid>
          {!isSSR && AddressTextField && (
            <React.Suspense
              fallback={
                <Grid item sm={9}>
                  <ActionTextField
                    size="large"
                    variant="filled"
                    color="secondary"
                    label="Enter your property address"
                    placeholder="123 Main St, Destin, FL 32541"
                    fullWidth
                    onSubmit={() => {}}
                  />
                </Grid>
              }
            >
              <Grid item sm={9}>
                <AddressTextField
                  size="large"
                  variant="filled"
                  color="secondary"
                  label="Enter your property address"
                  placeholder="123 Main St, Destin, FL 32541"
                  ctaContent={
                    loading ? (
                      <Box
                        sx={{
                          width: {
                            md: '116px',
                            xs: '93px',
                          },
                        }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size="24px" color="white" />
                      </Box>
                    ) : (
                      'Get a free offer'
                    )
                  }
                  onKeyDown={onKeyDown}
                  fullWidth
                  onChange={onChange}
                  onSubmit={onSubmit}
                  googleMapsApiKey={GOOGLE_MAPS_API_KEY || ''}
                />
              </Grid>
            </React.Suspense>
          )}
        </Grid>
      </Grid>
    </SectionLayout>
  )
}

export default HomepageHeroSectionDesktop
