import {
  ActionTextField,
  AddressTextField,
  GOOGLE_MAPS_API_KEY,
} from '@flock/shared-ui'
import { Grid, Box, Typography, CircularProgress } from '@mui/material'
import React from 'react'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { HomepageHeroSectionPresentationalProps } from './homepageHeroSectionTypes'
import HeroImage from '../../../images/hero-faint.webp'

const HomepageHeroSectionTablet = (
  props: HomepageHeroSectionPresentationalProps
) => {
  const { loading, onKeyDown, onChange, onSubmit } = props

  const isSSR = typeof window === 'undefined'

  return (
    <SectionLayout
      name="homepage-hero"
      backgroundColor="midnightBlue.main"
      backgroundImage={HeroImage}
      backgroundFull
    >
      <Grid container item sm={12} spacing={2}>
        <Grid
          item
          sm={12}
          display="flex"
          flexDirection="column"
          gap="32px"
          justifyContent="center"
        >
          {' '}
          <Typography variant="h1" color="white.main">
            Is your vacation home generating more stress than income?
          </Typography>
          <Typography variant="p1" color="white.main">
            Swap your home for shares of the Overmoon 721 Fund to defer taxes,
            receive distributions, and maintain appreciation potential.
          </Typography>
        </Grid>
        {!isSSR && AddressTextField && (
          <React.Suspense
            fallback={
              <Grid item sm={12}>
                <ActionTextField
                  size="large"
                  variant="filled"
                  color="secondary"
                  label="Your rental property address"
                  placeholder="Enter property address here"
                  fullWidth
                  onSubmit={() => {}}
                />
              </Grid>
            }
          >
            <Grid item sm={12}>
              <AddressTextField
                size="large"
                variant="filled"
                color="secondary"
                label="Your rental property address"
                placeholder="123 Main St, Destin, FL 32541"
                ctaContent={
                  loading ? (
                    <Box
                      sx={{
                        width: {
                          md: '116px',
                          xs: '93px',
                        },
                      }}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress size="24px" color="white" />
                    </Box>
                  ) : (
                    'Get a free offer'
                  )
                }
                onKeyDown={onKeyDown}
                fullWidth
                onChange={onChange}
                onSubmit={onSubmit}
                googleMapsApiKey={GOOGLE_MAPS_API_KEY || ''}
              />
            </Grid>
          </React.Suspense>
        )}
      </Grid>
    </SectionLayout>
  )
}

export default HomepageHeroSectionTablet
